(function ($) {
	$(window).load(function () {
		// owl-carousel
		$(".owl-carousel:not(.slider)").owlCarousel({
			items: 1,      
      lazyLoad: true,
			loop: true,

			// autoplay
			autoplay: true,
			autoplayTimeout: 7000,

			// speed
			smartSpeed: 500,

			// animation
			animateIn: "fadeIn",
			animateOut: "fadeOut",

			// navigation
			nav: true,
			navText: [
				'<i class="fas fa-chevron-left"></i>',
				'<i class="fas fa-chevron-right"></i>',
			],
			dots: true,

			// drag
			mouseDrag: false,
		});

		// slider
		$(".slider:not(.offcanvas-slider)").owlCarousel({
			items: 1,
			margin: 30,
			loop: true,
      lazyLoad: true,

			// autoplay
			autoplay: false,
			autoplayTimeout: 7000,

			// speed
			smartSpeed: 500,

			// navigation
			nav: true,
			navText: [
				'<i class="fas fa-chevron-left"></i>',
				'<i class="fas fa-chevron-right"></i>',
			],
			dots: true,

			// drag
			mouseDrag: true,

			// responsive
			responsive: {
				0: {
					items: 1,
				},
				576: {
					items: 2,
				},
				768: {
					items: 2,
				},
				992: {
					items: 3,
				},
				1200: {
					items: 3,
				},
			},
		});

		// offcanvas-slider
		$(".offcanvas-slider").owlCarousel({
			items: 1,
			margin: 20,
			loop: true,
      lazyLoad: true,
			stagePadding: 90,

			// autoplay
			autoplay: true,
			autoplayHoverPause: true,

			// slide speed
			autoplayTimeout: 7000,

			// speed
			smartSpeed: 500,

			// navigation
			nav: false,
			dots: true,

			// drag options
			mouseDrag: true,

			// responsive
			responsive: {
				0: {
					items: 1,
					stagePadding: 30,
				},
				576: {
					items: 1,
					stagePadding: 60,
				},
				768: {
					items: 2,
				},
				992: {
					items: 3,
					stagePadding: 60,
				},
				1200: {
					items: 3,
					stagePadding: 90,
				},
				1366: {
					items: 3,
					stagePadding: 135,
				},
				1400: {
					items: 4,
					stagePadding: 135,
				},
				1920: {
					items: 5,
					stagePadding: 135,
				},
				2560: {
					items: 7,
					stagePadding: 135,
				},
			},
		});

		// form-sent
		setTimeout(() => {
			var locationSearch = "" + document.location.search;
			if (
				(-1 === locationSearch.indexOf("form[sent]") &&
					-1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
				-1 === document.location.hash.indexOf("#form")
			) {
				return;
			}

			var $headerHeight = $(".header");
			var $message = $(".form-success-message");

			if (!$headerHeight.length || !$message.length) {
				return;
			}

			var currentScrollTop = $("html").scrollTop();
			var newScrollTop =
				$message.offset().top - $headerHeight.outerHeight() - 30;
			if (newScrollTop >= currentScrollTop) {
				return;
			}

			$("html, body").animate({
				scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30,
			});
		}, 500);
	});

	$(document).ready(function () {
		// sticky
		//require('../../vendor/w3media/framework/assets/js/V2/body-sticky-class');
		$(window).scroll(function () {
			var windowWidth = $(window).width();
			var $el = $("body").add(".header");

			if ($(window).scrollTop() > 0) {
				$el.addClass("sticky");
			} else {
				$el.removeClass("sticky");
			}
		});

		// navbar-toggler
		$(document).on("click", ".navbar-toggler", function () {
			$("body").toggleClass("navbar-collapse-active");
		});

		// fancybox
		$("a[data-fancybox]").fancybox({
			buttons: [
				//"zoom",
				//"share",
				//"slideShow",
				//"fullScreen",
				//"download",
				"thumbs",
				"close",
			],
		});

		// faq fix
		let faqlinks = document.querySelectorAll(".faq-category a");
		faqlinks.forEach((link) => {
			if (link.id) {
				link.id = "";
				link.href = "#";
			}
		});

		// scroll-to-content
		$(".js-3w-scroll-to-content").on("click", function (event) {
			event.preventDefault();
			var identifier = $(this).attr("data-target");
			var $element = $(identifier);

			if ($element) {
				$("html, body").animate({ scrollTop: $element.offset().top }, 500);
			}
		});

		// prevent scrolling on #TommyBookingSupport page
		$(window).on("hashchange", function (e) {
			if ($("#TommyBookingSupport").length > 0) {
				e.preventDefault();

				// disable scrolling
				$("body").css("overflow", "hidden");

				// set current scroll position
				$("html, body").animate({
					scrollTop: $("#TommyBookingSupport").offset().top - 100,
				});

				// enable scrolling
				setTimeout(() => {
					$("body").css("overflow", "auto");
				}, 10);
			}
		});

		// back to top button
		$("a.back-to-top").on("click", function (e) {
			e.preventDefault();

			$('html, body').animate({
				scrollTop: 0
			}, 10);
		});
	});
})(jQuery);
